/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/10/2022.
 */
import React, { useState } from 'react'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import ConsentIcon from '../../assets/consent.svg'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

// include styles
import './consent-modal.scss'
import Spinner from '../spinner/Spinner'

type Props = { setShow: (value: boolean) => void; show?: boolean }

export const CommercialConsentModal = ({ show, setShow }: Props) => {
  const hideModal = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    setShow(false)
  }

  const { t } = useTranslation()

  return (
    <DialogOverlay
      isOpen={show}
      dangerouslyBypassFocusLock={true}
      className="position-fixed rounded h-100"
      aria-label="consent"
    >
      <DialogContent className="container-fluid position-relative px-0 pb-2 bg-white " style={{ overflowY: 'scroll' }}>
        <button
          className="position-absolute btn-close btn "
          role="button"
          tabIndex={1}
          onKeyPress={hideModal}
          style={{ top: 20, right: 22, zIndex: 110 }}
          onClick={hideModal}
        ></button>
        <div className="">
          <div className="px-6 " data-body-scroll-lock-ignore="false">
            <div className="my-5 d-flex justify-content-center">
              <ConsentIcon className="mt-5"></ConsentIcon>
            </div>

            <h5 className="text-center">{t('commercialConsentPopup.headline')}</h5>
            <p className="mt-5 mb-4 text-center ">
              <Trans
                i18nKey="commercialConsentPopup.body"
                components={{
                  privacy: <a href="https://studiesandme.com/privacy/" target="_blank" rel="noopener noreferrer" />,
                  partners: (
                    <a href="https://studiesandme.com/collaborators/" target="_blank" rel="noopener noreferrer" />
                  ),
                }}
              ></Trans>
            </p>
            <button
              role="button"
              tabIndex={0}
              onClick={hideModal}
              className="btn  w-100 py-1 d-flex justify-content-center"
            >
              <p className="py-2 border px-6">
                <Trans i18nKey="back"></Trans>
              </p>
            </button>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}
