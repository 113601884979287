/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/04/2021.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './image-header.scss'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ImageHeaderStrides = ({ openDrawer, readMoreRef, signupRef }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  //
  // we are using two different formhooks for mobile and desktop
  //

  const handleScrollToElement = (event) => {
    ///window.scrollTo({ behavior: 'smooth', top: designRef.current.offsetTop })
    readMoreRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const gotoSignup = () => {
    if (isMobile) {
      openDrawer()
    } else {
      signupRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="d-flex position-relative image-header mt-md-4 ">
      <div className="d-none d-md-block container-fluid position-absolute h-100 " style={{ zIndex: -10 }}>
        <div className="container px-0 px-md-2 h-100">
          <div className="row h-100">
            <div className="d-none d-md-block col-12 h-100 px-0 ">
              <StaticImage
                className="h-100 w-100 "
                objectFit="cover"
                src="../../images/header_image.png"
                objectPosition="50% 0%"
                alt="studies and me"
                quality={100}
                placeholder="none"
              ></StaticImage>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-md-none container-fluid px-0 position-absolute h-100 " style={{ zIndex: -10 }}>
        <div className="container px-md-2 h-100">
          <div className="row h-100">
            <div className=" col-12 h-100 px-0  " style={{}}>
              <StaticImage
                className="h-100 w-100 "
                objectFit="cover"
                src="../../images/header_image_mobil.png"
                objectPosition="0% 0%"
                alt="studies and me"
                quality={100}
                placeholder="none"
              ></StaticImage>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid content-container h-100">
        <div className="row h-100" style={{}}>
          <div className="col-12 h-100 d-flex align-items-center justify-content-center">
            <div className="px-3 ps-md-5 header-text text-white">
              {/* <h6 className="text-center text-md-start text-white mb-2 ">{t('header.headByline')}</h6> */}
              {/* Make a difference for people living with osteoarthritis */}
              <h2 className="text-center mt-4 mb-5">{t('header.headline')}</h2>
              {/* Contribute to new knowledge about future treatment of osteoarthritis by participating in a clinical study */}
              <p className="text-center text-white">{t('header.body')}</p>
              <div className="mt-5 d-flex flex-md-row flex-column align-content-center justify-content-center">
                <button
                  onClick={openDrawer}
                  className="d-block d-md-none btn btn-secondary px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4 text-white"
                >
                  {t('form.signupButton')} {/* IS MOBILE*/}
                </button>

                <button
                  onClick={gotoSignup}
                  className="d-none d-md-block btn btn-secondary text-white px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
                >
                  {t('form.signupButton')} {/* IS DESKTop*/}
                </button>

                <button
                  onClick={handleScrollToElement}
                  className="btn bg-white btn-shadow px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
                >
                  {t('header.readMoreButton')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageHeaderStrides
