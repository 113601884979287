/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31/08/2022.
 */
import React from 'react'

//import CheckMark from '../assets/check-mark.svg'
import CheckMark from '../assets/check-mark.svg'

import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const WhatsInItForYou = () => {
  const { t } = useTranslation()

  return (
    <div className="container">
      <div className="row " style={{}}>
        <div className="col-12 d-flex justify-content-center col-md-6 mt-4 mt-md-6 mt-md-0 ">
          <div className="px-md-7 px-0" style={{}}>
            <StaticImage
              className="h-100 w-100 p-0 "
              objectFit="contain"
              src="../images/whatsinit.png"
              objectPosition="0% 0%"
              alt="studies and me"
              quality={100}
              placeholder="none"
            ></StaticImage>
          </div>
        </div>
        <div className="col-12 col-md-6 my-0 my-md-6 d-flex justify-content-center">
          <div className="">
            {/* Whats In It For You */}
            <h4 className="mb-5 mt-0 mt-md-4 text-center text-md-start ">{t('whatsInItForYou.title')}</h4>
            <BulletItem txtKey="whatsInItForYou.text1"></BulletItem>
            <BulletItem txtKey="whatsInItForYou.text2"></BulletItem>
            <BulletItem txtKey="whatsInItForYou.text3"></BulletItem>
          </div>
        </div>
      </div>
    </div>
  )
}

const BulletItem = ({ txtKey }) => {
  const { t } = useTranslation()
  return (
    <div className="d-flex  mb-5">
      <div className="">
        <CheckMark style={{ marginTop: 6, width: '2rem', height: '2rem', fill: 'rgba(30,175,169,0.8)' }}></CheckMark>
      </div>
      <h5 className="ms-3 mt-2 h5-small">{t(txtKey)}</h5>
    </div>
  )
}

/*

<div className="container-fluid" style={{ background: '#FBFAF9' }}>
  <div className="container el-margin">
    <div className="row" style={{}}>
      <div className="col-12 col-md-6 mt-6 mx-auto text-center">
        {/!* Whats In It For You *!/}
        <h3>{t('questionBold1')}</h3>
      </div>
    </div>
    <div className="row" style={{}}>
      <div className="col-12 col-md-4 text-center p-4">
        <div className="p-4">
          <CheckMark></CheckMark>
        </div>
        {/!* You will be part of testing a new possible treatment for osteoarthritis *!/}
        <h5>{t('imageText1')}</h5>
      </div>
      <div className="col-12 col-md-4 text-center p-4">
        <div className="p-4">
          <CheckMark></CheckMark>
        </div>
        {/!* You will be guided through the study by health care professionals *!/}
        <h5>{t('imageText2')}</h5>
      </div>
      <div className="col-12 col-md-4 text-center p-4">
        <div className="p-4">
          <CheckMark></CheckMark>
        </div>
        {/!* Your knees will be thoroughly examined *!/}
        <h5>{t('imageText3')}</h5>
      </div>
    </div>
  </div>
</div>
*/
