import React, { useEffect, useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { dataStore } from '../../store/dataStore'
import { ComboBox } from '../combobox/ComboBox'
import './sign-up-form.scss'
import { CheckBox } from './CheckBox'
import { CheckBoxCommercial } from './CheckBoxCommercial'

import { ConsentModal } from '../consent/ConsentModal'
import { CommercialConsentModal } from '../consent/CommercialConsentModal'

type Props = {
  hideBtn: boolean
  handleSubmit: (onError) => void
  formHook: any
}

const BORDER_COLOR = '#b6b6b6'

export const SignUpForm = ({ hideBtn, formHook, handleSubmit }: Props) => {
  const { t, i18n } = useTranslation()
  const [showConsent, setShowConsent] = useState(null)
  const [showCommercialModal, setCommercialModal] = useState(null)

  const {
    register,
    setValue,
    formState: { errors },
  } = formHook

  const userLocation = dataStore((state) => state.userLocation)
  const validCountry = dataStore((state) => state.validCountry)

  const onSubmit = (data, e) => {
    e.preventDefault()

    setShowConsent({ data: data, language: i18n.language })
  }

  const onError = (err, e) => {
    console.log(' SignUpForm > err = ', err)
    console.log(' SignUpForm > e = ', e)
    e.preventDefault()
  }

  useEffect(() => {
    if (userLocation) {
      register('position')
      setValue('position', userLocation)
    }
  }, [userLocation])

  const commercialHeadline = t('commercialConsentForm.headline') || false

  return (
    <div className="signup-form container-fluid px-0">
      <ConsentModal showValue={showConsent}></ConsentModal>

      <CommercialConsentModal show={showCommercialModal} setShow={setCommercialModal}></CommercialConsentModal>

      <form>
        <div className="row" style={{}}>
          <div className="col-12 position-relative mb-2">
            <InputNameField
              formHook={formHook}
              formKey={'name'}
              icon={null}
              // Your name
              label={t('form.fieldName')}
            />
          </div>

          <div className="col-12 position-relative">
            <InputEmailField
              formHook={formHook}
              formKey={'email'}
              icon={null}
              // Your email
              label={t('form.fieldEmail')}
            />
          </div>
        </div>

        <div className="row" style={{}}>
          <div className="col-12 mt-2">{/*FILLER*/} </div>

          <ComboBox formHook={formHook}></ComboBox>
        </div>

        <CheckBox formHook={formHook} formKey={'acceptTerms'}></CheckBox>
        {commercialHeadline ? (
          <CheckBoxCommercial
            formHook={formHook}
            setShow={setCommercialModal}
            formKey={'acceptCommercial'}
          ></CheckBoxCommercial>
        ) : null}

        {hideBtn ? null : (
          <button onClick={handleSubmit(onSubmit, onError)} className="btn btn-secondary text-white w-100 mt-3 py-3">
            {/* Sign up now */}
            {t('form.signupButton')}
          </button>
        )}
      </form>
    </div>
  )
}

const InputEmailField = ({ formHook, formKey = 'test', label, icon }) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = formHook
  const { t } = useTranslation()
  const errorState = errors[formKey]
  const borderState = errorState ? 'red' : BORDER_COLOR

  return (
    <div className="">
      <div
        className="form-floating"
        style={{
          border: `1px solid ${borderState}`,
          borderRadius: 4,
        }}
      >
        <input
          id="floatingInput"
          className=" w-100 form-control "
          placeholder="dasdasd"
          style={{ border: 'none', outline: 'none !important', boxShadow: '0 0 0px #719ECE', fontSize: '1rem' }}
          {...register(formKey, {
            pattern: {
              value:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              // The entered email address is not in a valid format
              message: t('validEmail'),
            },
            // We need your email to be able to contact you with details for the study
            required: t('requiredEmail'),
          })}
        />

        <label htmlFor="floatingInput" style={{ color: '#8a8a8a', fontSize: '1rem' }}>
          {t('form.fieldEmail')}
        </label>
      </div>
      {errorState ? <div className="p-small text-danger mt-1">{errorState.message}</div> : null}
    </div>
  )
}

const InputNameField = ({ formHook, formKey = 'test', label, icon }) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = formHook
  const { t } = useTranslation()
  const errorState = errors[formKey]
  const borderState = errorState ? 'red' : BORDER_COLOR

  return (
    <div className="">
      <div
        className="form-floating"
        style={{
          border: `1px solid ${borderState}`,
          borderRadius: 4,
        }}
      >
        <input
          id="floatingInput"
          className=" w-100 form-control "
          placeholder="Your name"
          style={{ border: 'none', outline: 'none !important', boxShadow: '0 0 0px #719ECE', fontSize: '1rem' }}
          {...register(formKey, {
            // Please enter a minimum of 2 charaters
            validate: (value) => value.length > 3 || t('nameMinimum'),
            // Please enter your name
            required: t('nameRequired'),
          })}
        />

        <label htmlFor="floatingInput" style={{ color: '#8a8a8a', fontSize: '1rem' }}>
          {t('form.fieldName')}
        </label>
      </div>
      {errorState ? <div className="p-small text-danger mt-1">{errorState.message}</div> : null}
    </div>
  )
}
