import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import { BottomDrawerSheet } from '../components/drawer/BottomDrawerSheet'

import { dataStore } from '../store/dataStore'
import URLSearchParams from '@ungap/url-search-params'
import { setUtm } from '../utils/utm'
import { WhatsInItForYou } from '../components/WhatsInItForYou'

import ImageHeaderStrides from '../components/header/ImageHeaderStrides'
import { FactsAboutTheStudy } from '../components/FactsAboutTheStudy'
import { WhatIsStudyAbout } from '../components/WhatIsStudyAbout'

import { DEBUG } from '../constant'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const IndexPage = (props) => {
  // should give the same output as the useTranslation hook
  // const language = props.data.locales.edges[0].node.language

  const location = props.location
  const { t } = useTranslation()

  const hasQuery = location.search
  setUtm(hasQuery)

  const getLocation = dataStore((state) => state.getLocation)
  const [showDrawer, setShowDrawer] = useState(false)

  const readMoreRef = useRef()
  const signupRef = useRef()

  useEffect(() => {
    const queryString = new URLSearchParams(location.search)

    const testIp = queryString.get('testip') || ''

    getLocation(testIp)
  }, [getLocation, location.search])

  const openDrawer = () => {
    setShowDrawer(true)
  }

  const closeDrawer = (value) => {
    setShowDrawer(value)
  }

  const scrollToSignup = (event) => {
    ///window.scrollTo({ behavior: 'smooth', top: designRef.current.offsetTop })
    signupRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  /*if (!DEBUG) {
    return <h5 className="p-4">Project coming soon</h5>
  }*/

  return (
    <Layout location={location} title={'Levi04'}>
      <Seo title={t('header.headline')} />

      <ImageHeaderStrides readMoreRef={readMoreRef} signupRef={signupRef} openDrawer={openDrawer}></ImageHeaderStrides>

      <div ref={readMoreRef} className="container mt-4 px-3">
        <div className="row">
          <div
            className="col-12"
            style={{
              border: '1px solid rgba(0,0,0,.1)',
              borderRadius: 6,
              background: 'linear-gradient(-60deg, rgba(99, 112, 131, 0.05) 100%, rgba(99, 112, 131, 0.01) 70%)',
              boxShadow: '0px 8px 20px -10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <WhatsInItForYou></WhatsInItForYou>
          </div>
        </div>
      </div>

      <div ref={signupRef} className="container mt-5 mb-7 px-3 ">
        <div className="row">
          <div
            className="col-12"
            style={{
              border: '1px solid rgba(0,0,0,.1)',
              borderRadius: 6,
              background: 'linear-gradient(-60deg, rgba(99, 112, 131, 0.05) 100%, rgba(99, 112, 131, 0.01) 70%)',
              boxShadow: '0px 8px 20px -10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <WhatIsStudyAbout></WhatIsStudyAbout>
          </div>
        </div>
      </div>

      <FactsAboutTheStudy scrollToSignup={scrollToSignup}></FactsAboutTheStudy>

      <BottomDrawerSheet openDrawer={showDrawer} closeFunc={closeDrawer}></BottomDrawerSheet>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
